<template>
  <div :class="[wrapperClass]" class="countdown-item">
    <div class="countdown-item__number">{{ value }}</div>
    <span class="countdown-item__text">{{ label }}</span>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      required: true,
      type: Number,
    },
    label: {
      required: true,
      type: String,
    },
  },
  computed: {
    wrapperClass() {
      return this.value > 99 ? "countdown-item--small" : "";
    },
  },
};
</script>
